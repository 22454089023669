import { customElement } from "lit/decorators.js";
import { html, LitElement, unsafeCSS } from "lit";
import styles from "./promo-loader.scss?inline";

@customElement("promo-loader")
export class PromoLoader extends LitElement {
  static styles = unsafeCSS(styles);

  render() {
    return html`
      <div class="loading">
        <div class="loading__spinner"></div>
      </div>
    `;
  }
}
