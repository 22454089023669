import styles from "./promo-placeholder.scss?inline";
import { html, unsafeCSS, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { translate } from "../../utils/i18n";
import placeholderIcon from "../../assets/404.svg?inline";

@customElement("promo-placeholder")
export class PromoPlaceholder extends LitElement {
  static styles = unsafeCSS(styles);

  render() {
    return html`
      <div class="promo_placeholder">
        <div class="promo_placeholder__img">
          <img src=${placeholderIcon} alt="placeholder" />
        </div>
        <div class="promo_placeholder__content">
          <h2 class="promo_placeholder__content__title">
            ${translate("placeholder.title")}
          </h2>
          <p class="promo_placeholder__content__text">
            ${translate("placeholder.text")}
          </p>
        </div>
      </div>
    `;
  }
}
